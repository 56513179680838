import './bootstrap';
import '../css/app.css';
import {createApp, h} from 'vue';
import {createInertiaApp} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/vue.m';
import Tailwind from 'primevue/passthrough/tailwind';
import {usePassThrough} from 'primevue/passthrough';
import primevuePassThroughOverrides from './primevuePassThroughOverrides.js';

const appName = import.meta.env.VITE_APP_NAME || 'Smart-SDR';

import PrimeVue from 'primevue/config';
import BadgeDirective from 'primevue/badgedirective';
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';

import '../css/assets/styles.scss';
import 'primeicons/primeicons.css';

const tailwindPassThrough = usePassThrough(Tailwind, primevuePassThroughOverrides, {
    mergeProps: true,
    mergeSections: true,
});

createInertiaApp({
    title: function (title) {
        if (title) {
            return `${title} - ${appName}`
        }
        return appName
    },
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        const app = createApp({render: () => h(App, props)})
            .use(plugin)
            .use(ZiggyVue, Ziggy);

        app.use(PrimeVue,{
            unstyled: false,
            ripple: true,
            pt: tailwindPassThrough
        });
        app.use(ToastService);
        app.use(DialogService);
        app.use(ConfirmationService);

        app.directive('tooltip', Tooltip);
        app.directive('badge', BadgeDirective);
        app.directive('ripple', Ripple);
        app.directive('styleclass', StyleClass);
        app.mount(el);

        return app;
    },
    progress: {
        color: '#4B5563',
    },
});
